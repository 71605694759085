// /**
//  * WordPress dependencies
//  */
// import { store, getContext } from "@wordpress/interactivity";

// // view.js
// interface TContext {}

// interface TState {}

// const { state } = store("ncmfse/post-media-player-block", {
// 	state: {} as TState,
// 	actions: {},
// 	callbacks: {},
// });
